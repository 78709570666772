<template>
    <div>
        <card-header title="Add-ons" icon="fa-layer-plus"/>

        <card-toolbar>
            <button @click="$openCard('new-addon', {registrationId: props.registrationId, tripId: registration.trip_id}, card)"><i class="fas fa-plus mr-2"/>New Add-on</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <reg-add-on-list-item
                    :card="card"
                    :addon="addon"
                    :double="!!(registration.second_id)"
                    v-for="addon in addons"
                    :active="(child && child.definition.component === 'edit-addon') && child.props.addonId === addon.id.toString()"
                    @click="$openCard('edit-addon', {registrationId: props.registrationId, addonId: addon.id}, card)"
                />
            </card-list>
        </card-body>

        <card-footer>
            <strong>Total:</strong> {{total | currency}}
        </card-footer>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '../../TIER/components/CardHeader';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import CardBody from '../../TIER/components/CardBody';
    import CardList from '../../TIER/components/CardList';
    import FlightListItem from '../../components/FlightListItem';
    import RegAddOnListItem from "@/components/RegAddOnListItem";
    import CardFooter from "../../TIER/components/CardFooter";

    export default {
        props: ['card', 'child', 'props'],
        components: {CardFooter, RegAddOnListItem, FlightListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addons: [],
                registration: {}
            };
        },
        computed: {
            total: function() {
                let result = 0;
                for (let i = 0; i < this.addons.length; i++) {
                    result += (this.addons[i].override_price) ? this.addons[i].override_price : (this.registration.second_id) ? this.addons[i].double_cost : this.addons[i].single_cost;
                }
            }
        },
        methods: {
            loadAddons: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/addons', {force}).then(response => {
                    this.addons = response.data;

                    http.get('/api/registrations/' + this.props.registrationId, {force}).then(response => {
                        this.registration = response.data;
                        this.$emit('loading', false);
                    });
                });

            },
            reload: function() {
                this.loadAddons(true);
            }
        },
        mounted() {
            this.loadAddons();
        }
    };
</script>
